<template>
    <v-card>
        <v-card-text>
                            <v-text-field class="mb-3"
                    v-model="searchString"
                    prepend-inner-icon="mdi-magnify"
                    label="Поиск..."
                    filled
                    background-color="transparent"
                    hide-details
                    ></v-text-field>
              <v-tabs fixed-tabs class="mb-3">
    <v-tab @click="tab='FT'">Особенности тушения пожаров</v-tab>
    <v-tab @click="tab='CD'">ГО знит</v-tab>
    <v-tab @click="tab='ON'">Отработка нормативов</v-tab>
  </v-tabs>
     
            <v-row><v-col cols='12' lg="4" md="6" sm="12" v-for='video in videos' :key='video.id'>
                    <v-card
                        class="mx-auto "
                        >
                        <v-img
                            height="250"
                            :src="video.preview"
                            ></v-img>
                        <center><v-btn style='position: absolute; top: 100px;    margin-left: -36px;' @click='view(video)'><v-icon>mdi-play</v-icon></v-btn></center>
                        <v-card-title>{{video.name}}</v-card-title>
                        <v-card-text>
                            {{video.desc}}
                        </v-card-text>
                    </v-card>
                </v-col></v-row>
        </v-card-text>
        <v-dialog width='1024' v-model='videoModal'>
                    <video-player v-if="videoModal" class="video-player-box"
                      ref="videoPlayer"
                      :options='{
                      muted: false,
                      language: "ru",
                      sources: [{
                      type: "video/mp4",
                      src: tempVideo.coded
                      }],
                      poster: tempVideo.preview,
                      coding:false,
                      height:"800"
                      }'
                      :playsinline="true"
                      

                      >

        </video-player>
        </v-dialog>

    </v-card>
</template>
<style>
    .vjs-big-play-button{
        top: 50% !important;
        left: 50% !important;
        margin-left: -45px;
        margin-top: -22px;
    }
    .video-js{
        width:100%;

    }
</style>
<script>
        import 'video.js/dist/video-js.css'
    import 'videojs-contrib-hls';
    import { videoPlayer } from 'vue-video-player'
        const debounce = function (f, ms) {
        let timer = null;
        return function (...args) {
            const onComplete = () => {
                f.apply(this, args);
                timer = null;
            }
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(onComplete, ms);
        };
    }
    export default {
        name: "index",
        data: () => ({
            tab:'FT',
            searchString:'',
                videoModal: false,
                videos: [],
                tempVideo: {}
            }),
            components:{
              videoPlayer  
            },
        methods: {
            fetchData() {
                this.$http.get('video/list?string=' + this.searchString +'&topic='+this.tab).then((response) => {
                    this.videos = response.data
                })
            },
            view(video) {
                this.$set(this, 'tempVideo', video);
                this.videoModal = true
            },
            search: debounce(function () {
 
                    this.fetchData()
  
            }, 500),
        },
        watch: {
            tab(){
                this.fetchData()
            },
            searchString() {
                this.search()
            },
        },
        mounted() {
            this.fetchData()
        }
    }
</script>