var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-text-field',{staticClass:"mb-3",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск...","filled":"","background-color":"transparent","hide-details":""},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-tabs',{staticClass:"mb-3",attrs:{"fixed-tabs":""}},[_c('v-tab',{on:{"click":function($event){_vm.tab='FT'}}},[_vm._v("Особенности тушения пожаров")]),_c('v-tab',{on:{"click":function($event){_vm.tab='CD'}}},[_vm._v("ГО знит")]),_c('v-tab',{on:{"click":function($event){_vm.tab='ON'}}},[_vm._v("Отработка нормативов")])],1),_c('v-row',_vm._l((_vm.videos),function(video){return _c('v-col',{key:video.id,attrs:{"cols":"12","lg":"4","md":"6","sm":"12"}},[_c('v-card',{staticClass:"mx-auto "},[_c('v-img',{attrs:{"height":"250","src":video.preview}}),_c('center',[_c('v-btn',{staticStyle:{"position":"absolute","top":"100px","margin-left":"-36px"},on:{"click":function($event){return _vm.view(video)}}},[_c('v-icon',[_vm._v("mdi-play")])],1)],1),_c('v-card-title',[_vm._v(_vm._s(video.name))]),_c('v-card-text',[_vm._v(" "+_vm._s(video.desc)+" ")])],1)],1)}),1)],1),_c('v-dialog',{attrs:{"width":"1024"},model:{value:(_vm.videoModal),callback:function ($$v) {_vm.videoModal=$$v},expression:"videoModal"}},[(_vm.videoModal)?_c('video-player',{ref:"videoPlayer",staticClass:"video-player-box",attrs:{"options":{
                    muted: false,
                    language: "ru",
                    sources: [{
                    type: "video/mp4",
                    src: _vm.tempVideo.coded
                    }],
                    poster: _vm.tempVideo.preview,
                    coding:false,
                    height:"800"
                    },"playsinline":true}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }